import React from 'react'

interface ArrowProps {
  iconClass?: string
}

const SideArrow = ({ iconClass }: ArrowProps): JSX.Element => {
  return (
    <svg
      width="39"
      height="18"
      viewBox="0 0 39 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={iconClass}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.39206 1.23739L8 0L0 9L8 18L9.39206 16.7626L3.31977 9.93128L38.4966 9.93128V8.06877L3.31972 8.06877L9.39206 1.23739Z"
      />
    </svg>
  )
}

export default SideArrow
