import React, { useEffect, useRef } from 'react'

import MagneticButton from '../../scripts/magnetic-button'
import { navigate } from 'gatsby'

interface ButtonDetailedProps {
  title?: string
  body?: string
  href?: string
  text?: string
  isExternalLink: boolean
}

const ButtonDetailed = ({ title, body, href, text, isExternalLink }: ButtonDetailedProps): JSX.Element => {
  const buttonRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null)
  const handleClick: () => void = () => {
    if (href) {
      navigate(href)
    }
  }

  useEffect(() => {
    if (buttonRef.current !== null) {
      new MagneticButton(buttonRef.current)
    }
  }, [buttonRef])

  return (
    <div className="detailed-button">
      {title && <p className="body-large">{title}</p>}
      {body && <p className="body">{body}</p>}
      {href &&
        text &&
        (isExternalLink ? (
          <a ref={buttonRef} className="button-detailed" href={href} target="__blank">
            {text}
          </a>
        ) : (
          <button ref={buttonRef} onClick={handleClick} className="button-detailed">
            {text}
          </button>
        ))}
    </div>
  )
}

export default ButtonDetailed
