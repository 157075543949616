import * as React from 'react'

import ButtonDetailed from '../components/atoms/ButtonDetailed'
import ButtonStandard from '../components/atoms/ButtonStandard'
import { ButtonsType } from '../types/types'

export const HeadingTag = (headlineLevel: number, className: string, children: string): JSX.Element => {
  switch (headlineLevel) {
    case 2:
      return <h2 className={`h2 ${className}`} dangerouslySetInnerHTML={{ __html: `<div>${children}</div>` }} />
    case 3:
      return <h3 className={`h3 ${className}`} dangerouslySetInnerHTML={{ __html: `<div> ${children} </div>` }} />
    case 4:
      return <h4 className={`h4 ${className}`} dangerouslySetInnerHTML={{ __html: `<div>${children}</div>` }} />
    case 5:
      return <h5 className={`h5 ${className}`} dangerouslySetInnerHTML={{ __html: `<div>${children}</div>` }} />
    case 6:
      return <h6 className={`${className}`} dangerouslySetInnerHTML={{ __html: `<div>${children}</div>` }} />
    default:
      return <h1 className={`h1 ${className}`} dangerouslySetInnerHTML={{ __html: `<div>${children}</div>` }} />
  }
}

export const ColorSchemeSelector = (className: string, altBg = false, darkMode = false): string => {
  return `${className}${altBg ? ' alt-bg' : ''}${darkMode ? ' dark-mode' : ''}${!altBg && !darkMode ? ' light' : ''}`
}

let currentScrollTop: number
export const lockBody = (): void => {
  currentScrollTop = window.pageYOffset
  document.body.classList.add('locked')
  document.body.style.top = `-${currentScrollTop}px`
}

export const unlockBody = (): void => {
  document.body.classList.remove('locked')
  document.body.style.top = ''
  requestAnimationFrame(() => {
    window.scrollTo(0, currentScrollTop)
  })
}

export const checkIfOdd = (num: number): boolean => (num & 1 ? true : false)

export const fetchSVG = (svgPath: string): string | void => {
  if (typeof XMLHttpRequest !== 'undefined') {
    const xhr = new XMLHttpRequest()
    let SVGMarkUp
    xhr.open('GET', svgPath, false)
    xhr.overrideMimeType('image/svg+xml')
    xhr.onload = function () {
      SVGMarkUp = xhr.responseXML?.documentElement
    }
    xhr.send('')
    return SVGMarkUp.outerHTML
  }
}

export const plyrOptions = {
  controls: [
    'play-large',
    'play',
    'progress',
    'current-time',
    'mute',
    'volume',
    'captions',
    'settings',
    'pip',
    'airplay',
    'fullscreen',
  ],
  clickToPlay: true,
  volume: 1,
}

export const renderButtons = (buttons: ButtonsType[]): JSX.Element[] => {
  return buttons.map((button, i) => {
    if (button.__typename === 'DatoCmsButtonStandard') {
      return (
        <ButtonStandard
          href={button.buttonUrl}
          text={button.buttonText}
          isExternalLink={button.isExternalLink}
          key={i}
        />
      )
    } else if (button.__typename === 'DatoCmsButtonDetailed') {
      return (
        <ButtonDetailed
          title={button.title}
          body={button.body}
          href={button.buttonUrl}
          text={button.buttonText}
          isExternalLink={button.isExternalLink}
          key={i}
        />
      )
    }
    return <></>
  })
}
